















/* tslint:disable:no-console */
import { Component, Vue } from 'vue-property-decorator';

import updateTdk from "@/utils/tdk";
import NavBar from '@/components/Nav-bar.vue';
import { getHomePageData } from '@/api'
// @Component 修饰符注明了此类为一个 Vue 组件
@Component({
  name: 'About',
  components: { NavBar },
})
export default class About extends Vue {
  private navs: any[] = [{name: '关于mlink应用平台', link: ''}];
  private tdk(self: any) {
    return {
      title: `-关于我们`,
    };
  }
  private serverPrefetch(): any {
    updateTdk(this);
  }
}
